import { useAutoAnimate } from "@formkit/auto-animate/react";
import dayjs from "dayjs";
import { motion, useAnimation, useInView } from "framer-motion";
import { gapi } from "gapi-script";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IcOutlineCancel,
  IcRoundCheckCircleOutline,
} from "../components/IconCustom";
import { SolarUserCircleBold } from "../components/Icons";
import { RANGE_SHEETS } from "../utils/Contant";

const URL_API =
  "https://script.google.com/macros/s/AKfycbzVh0mBsKlLKX7C3KtSkzKJgF1aJPfVAeGXcgY6knDJ3FUdtXUrW3P3ZvT2sgwKKV4JVA/exec";

const optionsCountGuest = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
];

export default function RemarkWedding() {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, {
    once: true,
    threshold: 0.1,
  });

  const [autoAnimate] = useAutoAnimate({
    transition: {
      scale: {
        from: 1, // Skala awal
        to: 0, // Skala akhir, 0 berarti elemen menghilang
        duration: 300, // Durasi dalam milidetik
        easing: "ease-in-out", // Jenis easing
      },
    },
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      remark: "",
      present: "",
      count_guest: "",
    },
  });
  const [data, setData] = useState([]);
  const [presentFlag, setPresentFlag] = useState("TRUE");
  const [fields, setFields] = useState("1");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: process.env.REACT_APP_SHEETS_API_KEY,
          clientId: process.env.REACT_APP_CLIENT_ID,
          discoveryDocs: [
            "https://sheets.googleapis.com/$discovery/rest?version=v4",
          ],
          scope: "https://www.googleapis.com/auth/spreadsheets",
        })
        .then(() => {
          gapi.client.load("sheets", "v4", () => {
            fetchData();
          });
        })
        .catch((error) => {
          console.error("Error initializing API client:", error);
        });
    }
    gapi.load("client:auth2", start);
  }, []);

  const fetchData = async () => {
    try {
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
        range: RANGE_SHEETS,
      });
      setData(response.result.values);
    } catch (err) {
      console.error("Error fetching data: ", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function onSubmit(data) {
    if (data.present === "") return;
    setIsLoading(true);
    const params = {
      name_guest: data.name_guest,
      remark: data.remark,
      present: data.present,
      count_guest: data.count_guest || 0,
      created_date: dayjs().format("YYYY/MM/DD"),
    };

    try {
      await fetch(URL_API, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      fetchData();
      reset();
      setPresentFlag("");
    } catch (err) {
      console.error("Error: ", err);
    } finally {
      setIsLoading(false);
    }
  }

  const formElements = [
    {
      type: "input",
      name: "name_guest",
      placeholder: "nama kamu...",
      required: true,
    },
    {
      type: "textarea",
      name: "remark",
      placeholder: "ucapan kamu...",
      required: true,
      maxLength: 500,
    },
    {
      type: "select",
      name: "present",
      required: true,
      options: [
        { value: "", label: "Pilih" },
        { value: "TRUE", label: "Hadir" },
        { value: "FALSE", label: "Tidak Hadir" },
      ],
      onChange: (e) => {
        setPresentFlag(e.target.value);
        if (e.target.value === "FALSE") {
          reset({ value: "count_guest" });
        }
      },
      value: presentFlag,
    },
  ];

  // Fungsi untuk memeriksa dan mengatur ulang fields jika tidak valid
  useEffect(() => {
    if (presentFlag === "TRUE") {
      setFields(optionsCountGuest[0].value); // Set ke nilai default yang valid jika saat ini tidak valid
    }
  }, [presentFlag, setFields]);

  return (
    <section
      id="story-love-elementor"
      className="w-full min-h-[700px] bg-beige pt-10"
    >
      <div className="border border-t-[1px] mb-10 mx-auto w-[80%]"></div>
      <article className="w-full h-20 flex flex-col items-center space-x-5 bg-transparent pr-6 relative">
        <h2 className="font-medium font-greatVibes text-sageDark text-center text-4xl mb-1 after:content-['-'] before:content-['-'] after:ml-2 before:mr-2">
          Wedding Wish
        </h2>
        <p className="text-sm text-center text-primary tracking-wide font-thin font-prata leading-5 whitespace-pre-wrap">
          Berikan ucapan terbaik untuk Kedua Mempelai & Konfirmasi Kehadiran
        </p>
      </article>
      <div className="flex flex-col space-y-5 items-start px-10 mt-7 drop-shadow-sm">
        <form
          className="flex flex-col items-center gap-3 w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formElements.map((element, index) => (
            <motion.div
              key={index}
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              className="w-full flex flex-col items-start justify-center gap-7"
              layout
            >
              {element.type === "input" && (
                <input
                  className="input-remark"
                  {...register(element.name)}
                  placeholder={element.placeholder}
                  required={element.required}
                />
              )}
              {element.type === "textarea" && (
                <textarea
                  className="input-remark"
                  {...register(element.name)}
                  placeholder={element.placeholder}
                  required={element.required}
                  maxLength={element.maxLength}
                />
              )}
              {element.type === "select" && (
                <select
                  className="block appearance-none input-remark"
                  {...register(element.name)}
                  onChange={element.onChange}
                  value={element.value}
                >
                  {element.options.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
            </motion.div>
          ))}
          {presentFlag === "TRUE" && (
            <select
              className="block appearance-none input-remark"
              {...register("count_guest")}
              onChange={(e) => setFields(e.target.value)}
              value={fields}
            >
              {optionsCountGuest.map((it) => (
                <option key={it.label} value={it.value}>
                  {it.label}
                </option>
              ))}
            </select>
          )}
          <motion.input
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            className="w-[50%] text-lg mt-4 p-1 font-cormorantUpright text-beige border-none rounded-lg bg-primary shadow-sm outline-none cursor-pointer"
            type="submit"
            disabled={isLoading}
            value={isLoading ? "Loading..." : "Send"}
          />
        </form>
      </div>
      <div
        id="wedding-wish-box"
        className="custom-scrollbar w-full h-[350px] px-5 py-2 mt-11 overflow-y-scroll glass-beige shadow-none rounded-b-none"
      >
        <div ref={autoAnimate}>
          {data.slice(1, 99).map((wish, idx) => {
            return (
              <div
                key={`wish-${wish[0]}`}
                className="flex items-start justify-start space-x-3 mb-2"
              >
                <div>
                  <SolarUserCircleBold />
                </div>
                <div>
                  <article className="flex items-center gap-2">
                    <p className="text-sm text-primary">{wish[0]}</p>
                    {wish[2] !== "TRUE" ? (
                      <i className="text-red-600">
                        <IcOutlineCancel />
                      </i>
                    ) : (
                      <i className="text-green-600">
                        <IcRoundCheckCircleOutline />
                      </i>
                    )}
                  </article>
                  <p className="text-xs font-thin text-primary mb-1">
                    {wish[4]}
                  </p>
                  <p className="text-sm font-normal font-nunitoSans text-primary line-clamp-3 leading-5">
                    {wish[1]}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
