import {
  AnimatePresence,
  motion,
  useAnimation,
  useInView,
} from "framer-motion";
import React, { useRef } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Each } from "../utils/Each";

const Potrait1 = require("../assets/prewedding/galeri_1.JPG");
const Potrait2 = require("../assets/prewedding/galeri_2.JPG");
const Potrait3 = require("../assets/prewedding/galeri_3.JPG");
const Potrait4 = require("../assets/prewedding/galeri_6.JPG");
const Potrait5 = require("../assets/prewedding/galeri_7.JPG");
const Potrait6 = require("../assets/prewedding/galeri_8.JPG");
const Landscape = require("../assets/prewedding/galeri_4.JPG");

const image = [
  {
    src: Potrait1,
    alt: "prewed-foto",
  },
  {
    src: Potrait2,
    alt: "prewed-foto",
  },
  {
    src: Potrait3,
    alt: "prewed-foto",
  },
  {
    src: Potrait4,
    alt: "prewed-foto",
  },
  {
    src: Potrait5,
    alt: "prewed-foto",
  },
  {
    src: Potrait6,
    alt: "prewed-foto",
  },
];

export default function GaleryElementor() {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <section
      id="galery-elementor"
      key="gallery-elementor"
      className=" w-full h-fit pt-10 pb-10 bg-primary relative"
    >
      <AnimatePresence key={"div-gallery-elementor"}>
        <div className="w-full h-20 flex flex-row items-center justify-end space-x-4 bg-transparent pr-6 relative">
          <div className="border-t-2 rounded-r-full w-full border-beige opacity-70" />
          <motion.article
            animate={controls}
            initial="hidden"
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 },
            }}
            transition={{ duration: 1.3, delay: 0.2, ease: "linear" }}
            className="relative min-w-max h-full flex-grow"
          >
            <p className="text-3xl md:text-4xl text-right text-yellow font-cinzelDecorative leading-tight">
              Our Gallery
            </p>
            <p className="text-beige font-greatVibes text-4xl md:text-5xl text-right leading-3">
              of Moments
            </p>
          </motion.article>
        </div>

        <div
          ref={ref}
          className="w-full h-full px-0 my-10 flex flex-col items-center justify-center"
        >
          <PhotoProvider
            maskOpacity={0.5}
            speed={() => 500}
            easing={(type) =>
              type === 2
                ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                : "cubic-bezier(0.34, 1.56, 0.64, 1)"
            }
          >
            <div className="w-full h-fit glass-beige flex flex-col space-y-2 px-5 py-3">
              {/* Grid col 3 */}
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 ">
                <AnimatePresence key={"div-photo-grid-elementor"}>
                  <Each
                    of={image}
                    render={(img, index) => (
                      <PhotoView key={`photo-${index}`} src={img.src}>
                        <motion.img
                          src={img.src}
                          alt=""
                          className="w-[100%] h-[200px] md:w-[200px] md:h-[250px] object-cover object-center cursor-pointer rounded-sm"
                          animate={controls}
                          initial="hidden"
                          variants={{
                            hidden: {
                              opacity: 0,
                              x: index % 2 ? 100 : -100,
                              y: index % 2 ? -100 : 100,
                            },
                            visible: { opacity: 0.8, x: 0, y: 0 },
                          }}
                          transition={{
                            duration: 0.8,
                            delay: 0.2 + index,
                            // ease: cubicBezier(0.17, 0.55, 0.55, 1),
                            ease: "linear",
                          }}
                          layout
                        />
                      </PhotoView>
                    )}
                  />
                </AnimatePresence>
              </div>
              {/* end */}
              {/* Single */}
              <PhotoView src={Landscape}>
                <motion.img
                  src={Landscape}
                  alt=""
                  className="w-[100%] h-[180px] object-cover object-bottom  cursor-pointer rounded-sm"
                  animate={controls}
                  initial="hidden"
                  variants={{
                    hidden: { opacity: 0, y: 100 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  transition={{
                    duration: 0.8,
                    delay: 5.3,
                    ease: "linear",
                  }}
                  layout
                />
              </PhotoView>
              {/* end */}
              {/* Grid col 2 */}
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 "></div>
              {/* end */}
            </div>
          </PhotoProvider>
        </div>
      </AnimatePresence>
      {/* <div className="h-[300px] w-full bg-[url('/src/assets/image/orchid-golden.jpg')] bg-cover bg-center bg-no-repeat mix-blend-multiply" /> */}
    </section>
  );
}
