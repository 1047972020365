import React, { useRef } from "react";
import Bca from "../assets/image/BCA.svg";
import DanaLogo from "../assets/image/dana-logo.jpeg";
import Chip from "../assets/image/chip.png";
import Wallet from "../assets/image/wallet.png";
import { useAnimation, useInView, motion } from "framer-motion";

const description = {
  bca: {
    name: "a/n Anindita Putri Suwarno",
    rekening: "5775360582",
  },
  dana: {
    name: "a/n Fahmi Fauzan",
    rekening: "0851 5518 2262",
  },
  kado: "Metland Cibitung cluster Taman Cilandak blok N11 no. 1, kec. Cikarang Barat, kab. Bekasi, Jawa Barat. Kode pos 17520.",
};

export default function WeddingGift() {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, {
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const cardVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <section
      id="wedding-gift-elementor"
      className="w-full h-fit py-10 px-0 md:px-7 bg-sageLight"
    >
      <article className="w-full h-14 text-center">
        <p className="text-3xl text-pretty text-primary font-medium font-cinzelDecorative">
          Wedding Gift
        </p>
      </article>
      <div className="w-full h-fit bg-transparent rounded-none md:rounded-3xl px-4 md:px-0 pt-2 pb-8">
        <article className="text-sageDark text-pretty antialiased">
          <p className="font-crimsonPro text-base md:text-lg mb-4">
            Tanpa mengurangi rasa hormat kami bagi tamu yang ingin mengirimkan
            hadiah kepada kedua mempelai dapat mengirimkannya melalui :
          </p>
          {/* Transfer */}
          <div className="flex flex-col align-top justify-center gap-4">
            {/* Card 1 */}
            <motion.div
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={cardVariants}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              className="w-full max-h-[300px] glass-sage-light shadow border border-seconds rounded-2xl px-4 md:px-6"
            >
              <span className="flex flex-row items-end justify-end h-14">
                <img src={Bca} alt="logo" className="h-7" />
              </span>
              <div className="flex flex-row w-full h-fit mb-4">
                <article className="flex flex-col items-start justify-end w-full text-primary font-nunitoSans text-left">
                  <img src={Chip} alt="bank" className="w-16 h-14 mb-4" />
                  <p className="text-base md:text-lg ">
                    No. rekening <strong>{description.bca.rekening}</strong>
                  </p>
                  <p className="text-sm md:text-lg">{description.bca.name}</p>
                  <div className="flex flex-row justify-end items-baseline w-full h-fit mb-5">
                    <ButtonCopyClip
                      title={"Salin No. Rekening"}
                      value={description.bca.rekening}
                    />
                  </div>
                </article>
              </div>
            </motion.div>
            {/* Card 2 */}
            <motion.div
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={cardVariants}
              transition={{ duration: 0.8, ease: "easeInOut", delay: 0.2 }}
              className="w-full max-h-[300px] glass-sage-light shadow border border-seconds rounded-2xl px-4 md:px-6"
            >
              <span className="flex flex-row items-end justify-end h-14 relative">
                <img
                  src={DanaLogo}
                  alt="logo"
                  className="h-14 object-contain object-right-top absolute top-5 right-0"
                />
              </span>
              <div>
                <img
                  src={Wallet}
                  alt="wallet"
                  className="w-fit h-16 object-contain object-left block ml-0 mr-auto"
                />
              </div>
              <article className="flex flex-col items-start justify-end w-full text-primary font-nunitoSans text-left">
                <p className="text-base md:text-lg">
                  No. telphone <strong>{description.dana.rekening}</strong>
                </p>
                <p className="text-sm md:text-lg">{description.dana.name}</p>
                <div className="flex flex-row justify-end items-baseline w-full h-fit mb-5">
                  <ButtonCopyClip
                    title={"Salin No. Telepone"}
                    value={description.dana.rekening}
                  />
                </div>
              </article>
            </motion.div>
            {/* Kado */}
            <motion.div
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={cardVariants}
              transition={{ duration: 0.8, ease: "easeInOut", delay: 0.4 }}
              className="w-full max-h-[300px] glass-sage-light shadow border border-seconds rounded-2xl px-4"
            >
              <div className="flex flex-row items-stretch justify-between max-h-fit mt-7">
                <article className=" text-left text-pretty text-primary flex-grow px-4 leading-8 font-nunitoSans">
                  <p className="font-bold text-lg">Kirim Kado</p>
                  <p className="text-justify text-sm tracking-tight">
                    {description.kado}
                  </p>
                </article>
                <i className="mx-2">
                  <svg
                    width="70"
                    height="70"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M91.6663 45.5017L80.2497 38.8767C91.2497 29.5017 80.208 12.0017 66.9997 17.96L63.3747 19.5017L62.9163 15.5017C62.4997 11.0017 60.1663 7.79333 57.083 5.91833C50.2497 1.9475 39.833 4.66833 38.1663 14.585L26.708 8.00166C22.708 5.66833 17.6247 7.04333 15.333 11.0433L11.1663 18.2517C9.99967 20.2517 10.708 22.7933 12.708 23.96L45.1663 42.71L51.4163 31.8767L58.6247 36.0433L52.3747 46.8767L84.833 65.6267C86.833 66.6683 89.4163 66.085 90.5413 64.085L94.708 56.8767C96.9997 52.8767 95.6663 47.7933 91.6663 45.5017ZM51.5413 20.835C47.9163 21.8767 44.9997 18.0017 46.833 14.7933C47.9163 12.7933 50.5413 12.1267 52.5413 13.2517C55.7497 15.1267 55.1247 19.96 51.5413 20.835ZM73.1663 33.335C69.583 34.3767 66.6663 30.5017 68.4997 27.2933C69.6247 25.2933 72.208 24.6267 74.1663 25.7517C77.3747 27.6267 76.7497 32.46 73.1663 33.335ZM86.958 70.335C88.6663 70.335 90.2913 69.7517 91.6663 68.7517V83.335C91.6663 87.96 87.958 91.6683 83.333 91.6683H16.6663C14.4562 91.6683 12.3366 90.7904 10.7738 89.2275C9.21098 87.6647 8.33301 85.5451 8.33301 83.335V45.835H42.2913L45.833 47.9183V83.335H54.1663V52.71L82.7913 69.21C84.0413 69.96 85.4163 70.335 86.958 70.335Z"
                      fill="#004D4B"
                    />
                  </svg>
                </i>
              </div>
              <div className="py-4 inline-flex items-baseline justify-end w-full">
                <ButtonCopyClip
                  title={"Salin Alamat"}
                  value={description.kado}
                />
              </div>
            </motion.div>
          </div>
        </article>
      </div>
    </section>
  );
}

function ButtonCopyClip({ title, value }) {
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      // alert("Teks berhasil disalin ke clipboard!");
    } catch (err) {
      console.error("Gagal menyalin teks ke clipboard:", err);
    }
  };
  return (
    <button
      className="flex flex-row items-center px-2 py-1 bg-seconds rounded-lg text-beige text-base"
      onClick={() => copyToClipboard(value)}
    >
      <i className="mr-2">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7 1.66406H9.455C7.985 1.66406 6.82 1.66406 5.90917 1.7874C4.97083 1.91406 4.21167 2.18073 3.61333 2.78156C3.01417 3.3824 2.74833 4.1449 2.6225 5.08656C2.5 6.00156 2.5 7.17073 2.5 8.64656V13.5116C2.5 14.7682 3.26667 15.8449 4.35583 16.2966C4.3 15.5382 4.3 14.4757 4.3 13.5907V9.41573C4.3 8.34823 4.3 7.4274 4.39833 6.69073C4.50417 5.90073 4.7425 5.14406 5.35417 4.5299C5.96583 3.91573 6.72 3.67656 7.50667 3.5699C8.24 3.47156 9.15667 3.47156 10.2208 3.47156H12.7792C13.8425 3.47156 14.7575 3.47156 15.4917 3.5699C15.2717 3.00854 14.8877 2.52647 14.3897 2.18653C13.8917 1.84658 13.3029 1.66451 12.7 1.66406Z"
            fill="#F9F8EB"
          />
          <path
            d="M5.5 9.49885C5.5 7.22719 5.5 6.09135 6.20333 5.38552C6.90583 4.67969 8.03667 4.67969 10.3 4.67969H12.7C14.9625 4.67969 16.0942 4.67969 16.7975 5.38552C17.5 6.09135 17.5 7.22719 17.5 9.49885V13.5155C17.5 15.7872 17.5 16.923 16.7975 17.6289C16.0942 18.3347 14.9625 18.3347 12.7 18.3347H10.3C8.0375 18.3347 6.90583 18.3347 6.20333 17.6289C5.5 16.923 5.5 15.7872 5.5 13.5155V9.49885Z"
            fill="#F9F8EB"
          />
        </svg>
      </i>
      Copy
    </button>
  );
}
