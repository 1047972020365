import React, { useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const story = [
  {
    title: "PDKT Mode-On",
    date: "12/04/2020",
    story:
      "Diawali dengan pertemuan setelah proses perkenalan via online, menu ngedate pertama kami dengan minuman boba request adinda putri. Karna pertemuan yang pertama kami saling malu-malu, namun makin penasaran dengan saling bertukar cerita kisah berdua.",
  },
  {
    title: "BUCIN",
    date: "22/05/2020",
    story:
      "Pada hari itu, kami berjanji untuk menjaga perasaan satu sama lain, bukan untuk orang lain. Empat tahun berlalu, penuh dengan tantangan yang tidak mudah, namun kami berhasil mempertahankan hubungan yang dinamis dan harmonis.",
  },
  {
    title: "OTW Halal",
    date: "28/04/2024",
    story:
      "Pada saat itu, kami memutuskan untuk mengikat hati dan melangkah ke jenjang yang lebih serius. Di hadapan dua keluarga yang penuh cinta, kami bertunangan dan mulai merancang setiap detail persiapan pernikahan kami. Setiap langkah yang kami ambil dipenuhi dengan harapan dan impian, menciptakan kisah cinta yang tak terlupakan.",
  },
  {
    title: "Alhamdulillah Saah..",
    date: "07/09/2024",
    story:
      "Di tengah hiruk pikuk persiapan yang tak kunjung usai, ketegangan semakin terasa di antara kedua calon mempelai. Hari yang dinantikan, 07 September 2024, semakin mendekat, membawa serta gelombang emosi yang tak terelakkan. Setiap detik terasa begitu berharga, setiap detail persiapan menjadi sangat penting.\n\nDan ketika hari itu tiba, di bawah langit cerah September, mereka berdiri di hadapan keluarga dan sahabat, mengucapkan janji suci dengan penuh keyakinan. Semua ketegangan dan hiruk pikuk persiapan seakan menghilang, digantikan oleh kebahagiaan yang tak terlukiskan. Mereka tahu, inilah awal dari kisah cinta yang akan mereka tulis bersama, selamanya.",
  },
];

export default function StoryLove() {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, {
    once: true, // Animasi hanya terjadi sekali
    threshold: 0.1, // Persentase elemen yang harus terlihat sebelum animasi dimulai
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <section
      id="story-love-elementor"
      className="w-full h-fit bg-sageDark py-10"
    >
      <div className="w-full h-20 flex flex-row items-center justify-between space-x-5 bg-transparent pr-6 relative">
        <div className="border-t-2 w-[100%] rounded-r-full border-beige opacity-70" />
        <article className="relative w-fit h-full">
          <p className="text-4xl md:text-5xl text-right text-[#023D46] font-cinzelDecorative leading-tight">
            Love
          </p>
          <p className="text-beige font-greatVibes text-3xl md:text-4xl text-right leading-3">
            Story
          </p>
        </article>
      </div>

      <div className="flex flex-col space-y-5 items-center mt-7">
        {story.map((story, idx) => (
          <article
            key={`story-love-${story.title}`}
            className="w-[calc(80%+50px)] h-fit glass-beige rounded-xl p-4 antialiased"
          >
            <motion.p
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              className="text-left text-2xl text-pretty text-primary font-crimsonPro leading-relaxed font-bold"
            >
              {story.title}
            </motion.p>
            <motion.p
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
              transition={{ duration: 0.8, ease: "easeInOut", delay: 0.2 }}
              className="text-left text-sm text-pretty text-beige font-nunitoSans font-semibold"
            >
              {story.date}
            </motion.p>
            <motion.p
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
              transition={{ duration: 0.8, ease: "easeInOut", delay: 0.4 }}
              className="text-left text-sm text-pretty indent-5 text-primary font-nunitoSans font-normal tracking-tight leading-6 whitespace-pre-wrap mt-2"
            >
              {story.story.split("\n").map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </motion.p>
          </article>
        ))}
      </div>
    </section>
  );
}
