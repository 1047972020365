import React, { useRef } from "react";
// import FlowerGold from "../assets/image/Flower-2.png";
import {
  AnimatePresence,
  motion,
  useAnimation,
  useInView,
} from "framer-motion";
import Dust4 from "../assets/image/dust-4.png";
import { IcRoundEditCalendar } from "../components/Icons";
import { Each } from "../utils/Each";
import { Bouquet, PepiconsPencilMap, Ring } from "../utils/Icons";

const visible = { opacity: 1, y: 0, transition: { duration: 1 } };
const textVarian1 = {
  hidden: { opacity: 0, y: 10 },
  visible,
};

const event = [
  {
    icon: <Ring />,
    title: "Akad Nikah",
    time: "Pukul 08.00 s.d 09.30 WIB",
    delay: 0.6,
  },
  {
    icon: <Bouquet />,
    title: "Prosesi Resepsi",
    time: "Pukul 10.00 s.d 13.00 WIB",
    delay: 1,
  },
];

const calenderSave = {
  title: encodeURIComponent("The Wedding of Putri & Fahmi"),
  startDate: "20240907T030000Z",
  endDate: "20240907T060000Z",
  detail: encodeURIComponent(
    "Kami mengundang Anda untuk merayakan pernikahan kami."
  ),
  location: encodeURIComponent(
    "Gedung Papandayan Ballroom Tambun, Plaza Metropolitan,Jl. Sultan Hasanudin lantai 2, Bekasi Regency, West Java 17510"
  ),
};

export default function EventElementor() {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${calenderSave.title}&dates=${calenderSave.startDate}/${calenderSave.endDate}&details=${calenderSave.detail}&location=${calenderSave.location}&sf=true&output=xml`;

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <section
      id="event-elementor"
      key="event-elementor"
      className="w-full h-fit bg-seconds pb-10 relative"
    >
      <AnimatePresence key={"div-event-elementor"}>
        <div className="w-full h-20 flex flex-row items-center justify-between space-x-5 bg-transparent pr-6 relative">
          <div className="border-t-2 w-[100%] rounded-r-full border-beige opacity-70" />
          <motion.article
            animate={controls}
            initial="hidden"
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 },
            }}
            transition={{ duration: 1, delay: 0.2, ease: "linear" }}
            className="relative w-fit h-full"
            layout
          >
            <p className="text-3xl md:text-4xl text-right text-yellow font-cinzelDecorative leading-tight">
              Wedding
            </p>
            <p className="text-beige font-greatVibes text-4xl md:text-5xl text-right leading-3">
              Events
            </p>
          </motion.article>
        </div>
        <div
          ref={ref}
          className="w-full h-fit text-base md:text-lg text-yellow font-crimsonPro font-th antialiased tracking-normal leading-5 text-center px-4 my-5"
        >
          <motion.p
            animate={controls}
            initial="hidden"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            transition={{ duration: 1.1, delay: 0.4, ease: "linear" }}
          >
            Bergabunglah bersama Kami menyaksikan sekaligus merayakan
            terbentuknya ikatan suci ini. Kami ingin Anda menjadi bagian dari
            hari istimewa kami.
          </motion.p>
        </div>
        <div
          className="w-full pt-3 px-5 md:px-8 flex flex-col space-y-5 relative bg-fill bg-no-repeat bg-top"
          style={{ backgroundImage: `url(${Dust4})` }}
        >
          {/* Box Event Content */}
          <>
            <Each
              of={event}
              render={(raw, index) => (
                <motion.div
                  key={`events-elementor-${raw.title}`}
                  className="w-full h-full static flex flex-row"
                  initial="hidden"
                  animate={controls}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, ease: "linear" },
                  }}
                  variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.8,
                        staggerChildren: 0.5,
                        delay: raw.delay,
                        ease: "linear",
                      },
                    },
                  }}
                  layout
                >
                  <div className="w-[75px] max-h-[80%] md:w-[75px] bg-primary rounded-l-xl drop-shadow-sm relative px-5">
                    <div className="w-full h-full bg-transparent flex flex-col items-center justify-center text-left text-beige space-y-8">
                      <i>{raw.icon}</i>
                      <p className="text-lg md:text-xl font-medium font-prata drop-shadow-md -rotate-90 leading-5">
                        {raw.title}
                      </p>
                    </div>
                  </div>
                  <article className="w-[calc(80%)] h-fit glass-beige rounded-br-[50px] outline-none">
                    <div className="w-full h-full flex flex-col space-y-2 items-start">
                      <motion.div
                        className="w-full text-left antialiased tracking-wide leading-5 py-5 px-3"
                        variants={{
                          hidden: { opacity: 0, y: 50 },
                          visible,
                        }}
                        transition={{
                          duration: 1.2,
                          delay: 0.4,
                          ease: "linear",
                        }}
                        layoutId="content-event-1"
                      >
                        <p className="text-base md:text-lg font-croissantOne antialiased text-primary font-semibold">
                          Sabtu, 07 September 2024
                        </p>
                        <p className="text-sm md:text-base text-beige">
                          {raw.time}
                        </p>
                      </motion.div>
                      <div className="border-t-2 border-primary w-[60%] "></div>
                      <motion.div
                        className="w-full h-full bg-transparent px-3 pt-3 pb-6 leading-5"
                        variants={textVarian1}
                        transition={{
                          duration: 1.2,
                          delay: 0.4,
                          ease: "linear",
                        }}
                      >
                        <p className="text-base md:text-lg font-croissantOne antialiased text-primary font-semibold">
                          Lokasi
                        </p>
                        <p className="text-sm md:text-base text-beige leading-5 tracking-normal">
                          Gedung Papandayan Ballroom Tambun, Plaza Metropolitan,
                          Jl. Sultan Hasanudin lantai 2, Bekasi Regency, West
                          Java 17510
                        </p>
                        <motion.a
                          variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.8,
                                delay: raw.delay % 2 ? 0.7 : 1,
                              },
                            },
                          }}
                          href="https://maps.app.goo.gl/QvEcehfuTAVJdV2b7"
                          className="flex flex-row items-center space-x-1 w-fit h-fit px-4 py-2 mt-5 text-sm text-primary bg-beige rounded-tl-3xl rounded-br-3xl drop-shadow-sm hover:drop-shadow-md hover:text-beige hover:bg-primary hover:scale-110 transition ease-in-out duration-300 delay-100"
                          target="__blank"
                        >
                          <PepiconsPencilMap className="text-xl font-semibold" />
                          <p className="font-nunitoSans antialiased font-medium">
                            Google Map
                          </p>
                        </motion.a>
                      </motion.div>
                    </div>
                  </article>
                </motion.div>
              )}
            />
          </>
          {/* end */}
        </div>
        <div className="w-full h-10 flex flex-col items-center justify-center mt-10">
          <a
            className="inline-flex items-center bg-transparent text-lg border text-beige font-crimsonPro rounded-md py-1 px-3 hover:bg-beige hover:text-primary hover:drop-shadow-md hover:scale-110 hover:-translate-y-1 text-pretty transition ease-in-out duration-200 delay-100 "
            href={googleCalendarUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Save the date&nbsp;&nbsp;
            <IcRoundEditCalendar />
          </a>
        </div>
        {/* Ornament Bottom */}
        {/* <motion.div
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
          transition={{ duration: 0.8, delay: 1 }}
          className="relative w-full h-[100px]"
        >
          <img
            id="powder-gold-asset"
            src={FlowerGold3}
            alt=""
            className="object-contain opacity-100 w-fit h-[150px] absolute bottom-[-3em] right-0"
          />
        </motion.div> */}
      </AnimatePresence>
    </section>
  );
}
