import React, { useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

export default function EndPage() {
  const controls = useAnimation();
  const ref = useRef();
  const inView = useInView(ref, {
    triggerOnce: true,
    threshold: 0.1,
  });
  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <section className="w-full h-[100dvh] bg-beige">
      <div className="w-full h-[calc(75%+50px)] bg-[url('/src/assets/prewedding/content_end.JPG')] bg-cover bg-left-bottom bg-no-repeat relative drop-shadow-sm">
        <div className="absolute inset-0 bg-gradient-to-b from-beige from-0% to-70% to-transparent bg-blend-overlay" />
        <motion.article
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
          transition={{ duration: 1.3, delay: 0.2 }}
          className="w-[calc(90%+20px)] h-[260px] glass-emerald bottom-[-8em] right-0 rounded-r-none rounded-l-3xl absolute p-4"
        >
          <p className="text-sm md:text-lg text-left text-pretty text-beige font-crimsonPro font-medium leading-6 mb-4">
            Atas kehadiran dan doa restu dari Bapak/Ibu/Saudara/I sekalian, kami
            mengucapkan Terima Kasih.
          </p>
          <p className="text-2xl md:text-3xl text-left text-pretty text-beige font-greatVibes leading-7">
            Wassalamualaikum Wr. Wb.
          </p>
          <p className="text-lg md:text-xl text-right font-pinyonScript text-yellow text-pretty leading-3 md:leading-5 mt-7">
            Kami yang berbahagia
          </p>
          <p className="text-5xl md:text-5xl text-right font-cormorantUpright text-pretty text-yellow tracking-tight leading-tight md:leading-relaxed">
            Putri & Fahmi
          </p>
        </motion.article>
      </div>
    </section>
  );
}
