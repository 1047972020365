import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import FlowerBottom from "../assets/image/flower-bottom.png";
import MobileCover from "../assets/prewedding/mobile_cover.JPG";
import { IcRoundSwipeUp } from "../utils/Icons";

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

function FirstCover({ elementRef, togglePlaying }) {
  const [isVisible, toggleVisible] = useState(false);
  const [guest, setGuest] = useState([]);
  const handleSlideUp = () => {
    if (!isVisible) {
      elementRef.current.play();
      toggleVisible(!isVisible);
      togglePlaying();
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      let query = hash.substring(1);
      query = query.replace(/%20|\+/g, " ");

      // Ubah string menjadi huruf kapital
      const capitalizedQuery = capitalizeWords(query).split(/& | Dan/);
      setGuest(capitalizedQuery);
    }
  }, []);

  const guestNamed = useMemo(() => {
    if (guest.length > 1) {
      return (
        <p>
          {guest[0]}
          <br />
          dan
          <br />
          {guest[1]}
        </p>
      );
    } else {
      return <p>{guest[0]}</p>;
    }
  }, [guest]);

  return (
    <motion.section
      id="cover-invitation"
      initial={{ y: 0 }}
      animate={{ y: isVisible ? -1920 : 0 }}
      transition={{ duration: 1 }}
      className="h-[100dvh] w-full absolute z-40 top-0 left-0 bg-beige"
    >
      <article className="flex flex-col justify-start items-center h-full overflow-hidden relative">
        <figure className="relative h-[70%] w-full">
          <motion.img
            src={MobileCover}
            alt="mobile-cover"
            className="object-cover object-center aspect-square h-full w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />
          <div className="w-full h-full bg-gradient-to-t from-primary via-primary/30 to-primary/0 absolute bottom-0 left-0"></div>
        </figure>
        <div className="absolute inset-0 z-10 bg-transparent">
          <div className="flex flex-col h-full w-full items-center justify-center">
            <article
              id="typografi-wedding"
              className="flex flex-col w-fit h-[50%] px-2 bg-transparent items-center justify-end space-y-6 text-center font-semibold text-yellow drop-shadow-md"
            >
              <motion.span
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.3, delay: 0.5 }}
              >
                <p className="text-xl font-pinyonScript gold-text text-center">
                  The Wedding Of
                </p>
                <p className="text-5xl font-cormorantUpright gold-text tracking-wide drop-shadow-[0_3px_2px_rgba(254,251,233,0.5)]">
                  Putri & Fahmi
                </p>
              </motion.span>
              <motion.span
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.3, delay: 0.7 }}
                className="text-sm font-normal leading-7 text-beige font-nunitoSans"
              >
                <p>Kepada Yth. Bapak/Ibu/Saudara/i</p>
                <p className="text-center leading-6 font-crimsonPro font-semibold text-lg">
                  {guest.length ? guestNamed : "Para Tamu"}
                </p>
              </motion.span>
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.3, delay: 0.9 }}
                className="w-fit px-2 py-1 rounded-full bg-yellow flex flex-row items-center space-x-1"
                onClick={handleSlideUp}
              >
                <i className="text-seconds">
                  <IcRoundSwipeUp />
                </i>{" "}
                <p className="text-xs font-normal font-poppins antialiased text-seconds">
                  Open Invitation
                </p>
              </motion.button>
            </article>
          </div>
        </div>
        <div className="w-full h-[30%] bg-primary relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.3, delay: 0.2 }}
            className="absolute bottom-0 right-0"
          >
            <img
              src={FlowerBottom}
              alt=""
              className="w-full h-full object-contain"
            />
          </motion.div>
        </div>
      </article>
    </motion.section>
  );
}

export default FirstCover;
