import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SongTrack from "../assets/music/song-track.mp3";
import CoupleElementor from "../pages/CoupleElementor";
import DateView from "../pages/DateView";
import EndPage from "../pages/EndPage";
import EventElementor from "../pages/EventElementor";
import GaleryElementor from "../pages/GaleryElementor";
import Quote from "../pages/Quote";
import RemarkWedding from "../pages/RemarkWedding";
import StoryLove from "../pages/StoryLove";
import WeddingGift from "../pages/WeddingGift";
import FirstCover from "./FirstCover";
import { NavbarFloating } from "./NavbarFloating";

const components = [
  { id: 1, component: <DateView /> },
  { id: 2, component: <CoupleElementor /> },
  { id: 3, component: <EventElementor /> },
  { id: 4, component: <GaleryElementor /> },
  { id: 5, component: <StoryLove /> },
  { id: 6, component: <WeddingGift /> },
  { id: 7, component: <Quote /> },
  { id: 8, component: <RemarkWedding /> },
  { id: 9, component: <EndPage /> },
];

export default function MobileView() {
  const audioRef = useRef();
  const [isPlaying, toggleIsPlaying] = useState({ play: false, isOPen: false });

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => console.log(error));
    }
  }, []);

  const handleAudioPlayPause = useCallback(() => {
    if (isPlaying.play) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    toggleIsPlaying((prev) => ({ ...prev, play: !prev.play }));
  }, [isPlaying.play]);

  return (
    <div
      id="wrapper-content"
      className={`h-full w-full relative overflow-x-hidden ${
        isPlaying.isOPen
          ? "overflow-y-auto scroll-m-0 scroll-ms-5"
          : "overflow-hidden"
      }`}
    >
      <FirstCover
        elementRef={audioRef}
        togglePlaying={() => toggleIsPlaying({ play: true, isOPen: true })}
      />
      <AnimatePresence key={"div-mobile-view"}>
        {isPlaying.isOPen && (
          <>
            {components.map(({ id, component }, index) => (
              <React.Fragment key={id}>{component}</React.Fragment>
            ))}
          </>
        )}
      </AnimatePresence>
      <NavbarFloating />
      <div className="relative">
        <audio
          id="audio-backsong"
          ref={audioRef}
          src={SongTrack}
          preload="auto"
          onLoad={handleAudioPlayPause}
          loop
          autoPlay
        />
        <motion.button
          id="playButton"
          className="fixed z-20 bottom-40 transform translate-y-1/2 right-5 text-white w-7 h-7 rounded-full border border-beige bg-seconds flex flex-col items-center justify-center"
          onClick={handleAudioPlayPause}
        >
          {isPlaying.play ? (
            <i>
              <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 4.5V19.5C20.25 19.8978 20.092 20.2794 19.8107 20.5607C19.5294 20.842 19.1478 21 18.75 21H15C14.6022 21 14.2206 20.842 13.9393 20.5607C13.658 20.2794 13.5 19.8978 13.5 19.5V4.5C13.5 4.10218 13.658 3.72064 13.9393 3.43934C14.2206 3.15804 14.6022 3 15 3H18.75C19.1478 3 19.5294 3.15804 19.8107 3.43934C20.092 3.72064 20.25 4.10218 20.25 4.5ZM9 3H5.25C4.85218 3 4.47064 3.15804 4.18934 3.43934C3.90804 3.72064 3.75 4.10218 3.75 4.5V19.5C3.75 19.8978 3.90804 20.2794 4.18934 20.5607C4.47064 20.842 4.85218 21 5.25 21H9C9.39782 21 9.77936 20.842 10.0607 20.5607C10.342 20.2794 10.5 19.8978 10.5 19.5V4.5C10.5 4.10218 10.342 3.72064 10.0607 3.43934C9.77936 3.15804 9.39782 3 9 3Z"
                  fill="#FFCD86"
                />
              </svg>
            </i>
          ) : (
            <i>
              <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.409 9.35331C21.8893 9.60872 22.291 9.99 22.5712 10.4563C22.8514 10.9226 22.9994 11.4563 22.9994 12.0003C22.9994 12.5443 22.8514 13.078 22.5712 13.5443C22.291 14.0106 21.8893 14.3919 21.409 14.6473L8.597 21.6143C6.534 22.7373 4 21.2773 4 18.9683V5.03331C4 2.72331 6.534 1.26431 8.597 2.38531L21.409 9.35331Z"
                  fill="#FFCD86"
                />
              </svg>
            </i>
          )}
        </motion.button>
      </div>
    </div>
  );
}
