import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const Content1 = require("../assets/prewedding/content_1.JPG");
const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
};

const weddingDate = "2024-09-07";

export default function DateView() {
  const calculateTimeLeft = () => {
    const difference = +new Date(weddingDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <section id="date-view-elementor" className="w-full h-full bg-beige">
      <div className="relative w-full h-[calc(70%+30px)] drop-shadow-xl">
        <motion.img
          src={Content1}
          className="w-full h-full object-cover object-center"
          alt="couple-foto"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "linear" }}
        />
        <div className="h-full w-full absolute z-10 bottom-0 left-0 bg-gradient-to-t from-seconds/30 to-seconds/35 bg-blend-multiply">
          <motion.div
            id="div-elementor-title"
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{
              visible: { transition: { staggerChildren: 0.5, delay: 0.4 } },
            }}
            className="flex flex-col items-center justify-end space-y-7 h-full w-full text-center text-beige drop-shadow-lg"
            layout
          >
            <motion.span
              variants={{
                hidden: { opacity: 0, y: -70 },
                visible,
              }}
            >
              <p className="font-pinyonScript text-lg antialiased">
                The Wedding Of
              </p>
              <p className="text-5xl font-cormorantUpright font-medium antialiased pt-0">
                Putri & Fahmi
              </p>
            </motion.span>
            <motion.div
              variants={itemVariants}
              className="font-prata text-base text-beige font-medium drop-shadow-md antialiased"
            >
              Sabtu, 07 September 2024
            </motion.div>
            <motion.article
              variants={itemVariants}
              className="w-full h-24 text-beige flex flex-row items-start justify-center space-x-3 bg-transparent"
              layout
            >
              <div className="box-countdown-date glass-beige rounded-lg">
                <p>
                  {addLeadingZero(timeLeft.days)} <br /> Hari
                </p>
              </div>
              <div className="box-countdown-date glass-beige rounded-lg">
                <p>
                  {addLeadingZero(timeLeft.hours)} <br /> Jam
                </p>
              </div>
              <div className="box-countdown-date glass-beige rounded-lg">
                <p>
                  {addLeadingZero(timeLeft.minutes)} <br /> Menit
                </p>
              </div>
              <div className="box-countdown-date glass-beige rounded-lg">
                <p>
                  {addLeadingZero(timeLeft.seconds)} <br /> Detik
                </p>
              </div>
            </motion.article>
          </motion.div>
        </div>
      </div>
      <motion.div
        animate={{
          y: [0, 10, 0],
          transition: { duration: 2, repeat: Infinity },
        }}
        layout
        className="w-full h-fit bg-transparent drop-shadow-md pt-12 flex flex-col items-center justify-start space-y-2 text-center"
      >
        <i>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3604 14.8916L13.3292 21.9229C13.2203 22.0321 13.0909 22.1188 12.9485 22.1779C12.8061 22.2371 12.6533 22.2675 12.4991 22.2675C12.3449 22.2675 12.1922 22.2371 12.0497 22.1779C11.9073 22.1188 11.7779 22.0321 11.669 21.9229L4.63778 14.8916C4.41762 14.6715 4.29395 14.3729 4.29395 14.0615C4.29395 13.7502 4.41762 13.4516 4.63778 13.2314C4.85793 13.0113 5.15651 12.8876 5.46785 12.8876C5.77919 12.8876 6.07778 13.0113 6.29793 13.2314L11.3282 18.2617V3.90625C11.3282 3.59545 11.4517 3.29738 11.6714 3.07761C11.8912 2.85784 12.1893 2.73438 12.5001 2.73438C12.8109 2.73438 13.109 2.85784 13.3287 3.07761C13.5485 3.29738 13.672 3.59545 13.672 3.90625V18.2617L18.7022 13.2305C18.9224 13.0103 19.221 12.8866 19.5323 12.8866C19.8436 12.8866 20.1422 13.0103 20.3624 13.2305C20.5825 13.4506 20.7062 13.7492 20.7062 14.0605C20.7062 14.3719 20.5825 14.6705 20.3624 14.8906L20.3604 14.8916Z"
              fill="#FFCD86"
            />
          </svg>
        </i>
        <p className="text-md text-yellow tracking-tighter antialiased font-poppins">
          Scroll Down
        </p>
      </motion.div>
    </section>
  );
}
