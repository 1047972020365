import {
  AnimatePresence,
  motion,
  useAnimation,
  useInView,
} from "framer-motion";
import React, { useRef } from "react";
import Flower4 from "../assets/image/Flower-4.webp";
import Flower5 from "../assets/image/Flower-5.webp";
import Butterfly from "../assets/image/butterfly.png";
import PowderGold from "../assets/image/powder-gold.png";
import Profile1 from "../assets/prewedding/profile_1.jpeg";
import Profile2 from "../assets/prewedding/profile_2.jpeg";

export default function CoupleElementor() {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const textMotion = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1,
      },
    },
  };
  const imgLeft = {
    hidden: { x: -50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };
  const imgRight = {
    hidden: { x: 100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };
  return (
    <section
      id="couple-elementor"
      key="couple-elementor"
      className="w-full h-fit outline-none flex flex-col space-y-0 bg-seconds relative"
    >
      <AnimatePresence key={"article-couple-section"}>
        <article
          ref={ref}
          className="w-full h-fit leading-4 px-4 mt-10 text-yellow text-center bg-transparent"
        >
          <motion.p
            key="animate-text-1"
            animate={controls}
            initial="hidden"
            className="text-3xl md:text-4xl leading-loose mb-1 font-greatVibes tracking-wide antialiased"
            variants={textMotion}
          >
            Assalamualaikum Wr. Wb.
          </motion.p>
          <motion.p
            key="animate-text-2"
            animate={controls}
            initial="hidden"
            className="text-base md:text-lg tracking-wide font-crimsonPro"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 50 },
            }}
            transition={{ duration: 1.3, delay: 0.2 }}
          >
            Tanpa mengurangi rasa hormat. Kami mengundang Bapak/Ibu/Saudara/i
            serta kerabat sekalian untuk menghadiri acara pernikahan kami :
          </motion.p>
        </article>
        <div className="flex flex-col justify-start space-y-[12%] pt-7">
          {/* CPW */}
          <div className="w-full bg-transparent flex flex-col items-start px-4 relative">
            <motion.div
              key="animate-div-cpw"
              className="relative w-[250px] md:w-[300px] h-fit rounded-br-[50px] glass-beige"
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 },
              }}
              transition={{ duration: 0.7, delay: 0.2, ease: "easeInOut" }}
              layout
            >
              <motion.img
                key="animate-img-cpw"
                animate={controls}
                initial="hidden"
                src={Profile2}
                alt="the-bride"
                className="object-cover w-[180px] h-[250px] md:w-[230px] md:h-[300px]"
                variants={imgLeft}
                transition={{ duration: 1, delay: 0.3, ease: "easeInOut" }}
                layoutId="photo-cpw"
              />
              <img
                id="powder-gold-asset"
                src={PowderGold}
                alt=""
                className="object-contain opacity-70 w-[300px] h-[300px] absolute top-[-9rem] left-[-7rem] z-[-10]"
              />
              <div>
                <p className="text-4xl text-beige font-greatVibes absolute top-1/3 -right-10 -rotate-90 antialiased">
                  The Bride
                </p>
              </div>
            </motion.div>
            <motion.div
              key="animate-div-summary-1"
              animate={controls}
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{ ease: "linear", duration: 1.5, delay: 0.9 }}
              className="text-left pt-4"
            >
              <p className="text-yellow text-[32px] leading-7 font-cormorantUpright font-semibold antialiased tracking-wide">
                Anindita Putri Suwarno
              </p>
              <p className="text-beige text-base leading-6 font-crimsonPro antialiased">
                Putri Bungsu Bapak F. Suwarno & Ibu Lisdiana
              </p>
            </motion.div>

            <motion.img
              id="flower-vertical-asset"
              key="animate-img-ornament-1"
              src={Flower5}
              alt=""
              className="object-contain w-[200px] h-[200px] absolute bottom-10 left-[11rem] md:left-[14rem]"
              animate={controls}
              initial="hidden"
              transition={{ ease: "linear", duration: 1, delay: 0.6 }}
              variants={imgLeft}
            />
          </div>
          {/* end */}

          <div className="flex flex-row space-x-4 items-center justify-center h-3 w-full text-center text-3xl opacity-70 font-cinzelDecorative text-beige drop-shadow-md">
            <span className="border-t-2 border-beige w-full"></span>
            <p>&</p>
            <span className="border-t-2 border-beige w-full"></span>
          </div>

          {/* CPP */}
          <div className="w-full bg-transparent flex flex-col text-right items-end px-4">
            <motion.div
              key="animate-div-cpp"
              className="relative w-[250px] md:w-[300px]  h-fit rounded-bl-[50px] glass-beige flex flex-row justify-end"
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 },
              }}
              transition={{ duration: 0.7, delay: 1.1, ease: "easeInOut" }}
              layout
            >
              <motion.img
                key="animate-img-cpp"
                animate={controls}
                initial="hidden"
                variants={imgRight}
                transition={{ duration: 1.4, delay: 1.2, ease: "easeInOut" }}
                src={Profile1}
                alt="the-groom"
                className="object-cover w-[180px] h-[250px] md:w-[230px] md:h-[300px]"
                layoutId="photo-cpp"
              />
              <motion.img
                id="powder-gold-asset"
                src={Flower4}
                alt=""
                className="object-contain w-[200px] h-[200px] absolute bottom-0 left-[-7.5rem] z-[-50]"
                animate={controls}
                initial="hidden"
                variants={imgRight}
                transition={{ duration: 1, delay: 1.2, ease: "easeInOut" }}
              />
              <div>
                <p className="text-4xl text-beige font-greatVibes absolute top-1/3 -left-10 tracking-wide rotate-90 antialiased">
                  The Groom
                </p>
              </div>
            </motion.div>
            <motion.div
              key="animate-div-summary-2"
              animate={controls}
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{ ease: "linear", duration: 1.5, delay: 1.3 }}
              className="text-right pt-4 leading-5 w-full"
              layout
            >
              <p className="text-yellow text-[32px] leading-7 whitespace-pre-line font-cormorantUpright font-semibold antialiased tracking-wide">
                Muhamad Fahmi Fauzan
              </p>
              <p className="text-beige text-base leading-6 font-crimsonPro antialiased">
                Anak Pertama Bapak M. Yayat Ruchiyat & Ibu Latifah
              </p>
            </motion.div>
          </div>
          {/* end */}
        </div>
        <motion.div
          key="animate-div-ornament-2"
          animate={{
            opacity: 1,
            y: [7, -7, 7],
            transition: { ease: "linear", duration: 2, repeat: Infinity },
          }}
          transition={{ ease: "linear", duration: 1.5, delay: 0.9 }}
          className="w-full h-fit flex flex-row justify-center items-center bg-transparent py-10"
        >
          <img
            id="butterfly-asset"
            src={Butterfly}
            alt=""
            className="object-contain object-center w-full h-[90px]"
          />
        </motion.div>
      </AnimatePresence>
    </section>
  );
}
