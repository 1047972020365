const CLIENT_ID =
  "488370204458-rgr17505vvr4uumfnlbqept1d431anm9.apps.googleusercontent.com";
const RANGE_SHEETS = "Sheet1!A:E";
const API_KEY = "AIzaSyBly99Bm_5MbUqh0IaaB5X5S1h4Umldjgc";

const GOOGLE_SHEETS_ID = "19xYofoiblNdARn4VKRX1kvVjKqnc-edUAtY3tafwwVI";

const GOOGLE_PRIVATE_KEY =
  "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDQGsi9mBxG3eaG\nOg2LaEBtldBcbpnnkz6XLyrm6zQlqsOYEJGJz8Rn8U6+RBxXd6KNs2zhvzC9eLjP\nPW9MYu7nfetepAHd2xtPm3KaKm+DnW1anBNz29i9pGs9JD2eTbGkBbb6DIK/I4GO\n7u3ZT4ZgvEv9VxPK2zMqNzHL9mZcR68CKSTdhWseCiw2wpAzEKNfE5Dot8zQNG7l\nCjD822zqaKpXh8G0B7U4hknngDBGs9uP60/kXF5PUY1d1039St2trz7e5B7JaH4P\nxrNw9Yqg/tD9o4wU7arEhgCHLtdFKry49yiYljvaXefDryYmNCT8fTKqcSj9DLx3\njd+Ce+R7AgMBAAECggEACe2FLxpPGwhiA3xIgiKOlEvF3Wr/V59cKI09ADgvTC65\n1wrPTqJFTeoipgwMyBVygU0SgDCFTDyyoFxfb2b2279Tl7e+SQ23X1RcosMsf7xm\nZ6Czgt71l5J89TVB5Ufylg8ZvBMVt7WjbCS03x2enQXOoD7juFU9uyFX9FGEb5l7\nwuZgungkPWqtvY6NCJdNiO4WGODAxZk1xnrAayvcy3uzea80kdz9N/MV9wyTUnKv\nTnhayp+mNdC7x0FEMOL+GnQW0aJQH4vFCGDYvNFy5AS35nQO8ZAR/vFN1gM1pLnm\ndFMgeBQpfmaYDWJH+cg6ezuuk3d7udXshRdaCzliMQKBgQD7cpw1XDp+dlFTHdUh\n69++qYSlVjNx2uBde7kXONHrMPZwk2rndDpyse6z0s8SNy89YQ/sAKEKHzidh4Lw\n1oW3Sq7Sb+gN8vRAm3dYt40xW8Zt97Xqy5dvuejMwe0uWFAyeKu8gkr7vxEdaHRW\nFoS0mo2sZN/lirozf5f1yDY7awKBgQDT30p6l4ivsLHVmSD3nw/3kErqgsRqKMq5\nNdpCWA3uWg42NrCgWpznEe2b4iCRTkduo3ub+sk4o07jeWQr2MODXd5MRu8mA/KT\nVcdCWethtPACzJ16LbNybsVCkKT354chOIJhjG/i1uGnRbVwybOX5KUR00HSyyaT\nsZg6/O3PMQKBgQCFruJj8DvdHlSQZwz4cupK+N9f89t4DdE5glzefsQEUczD/2xX\nz9B/SCBLlKmo2pQQAvmvFIqJ0h2Miw5Psw32bAtUMFDkkaXQb5ddIIE9m7h8oLUk\nNse1i9a8feH2wNcqbkOH/pGVFHkp9G3dky1Mw9rMq/jplf73AEAvWRmULwKBgEOo\nSmBbg4qXIjLTc3Ofl7m2mjXJDG/aFuKOD+SfudMjbuZhEIYkaXZgg0AENeIw3R63\nlgF5VEiB3e9GFHbsAYMxtoslG7lICW6DNd46WLAa14oNTTx225bvSI65IfUIOl6h\nbQE5l4EYnXaNDCO4RWF74jqO+7YUqX0AeVxc6JQBAoGBANLrYL7gHhlGEIoxCeIi\nj1+0WjS+a8ZYudnF9DcDJNTFPXCAPIfVeqyFkb6emaY+XCTACsHtbhRWHzA//krd\nRzWvXQiHAXt4xQ1I9z/Up6xTqVtfBRzgu2SiRq5Ipoz4t69TA5IOF16y0GugFYPL\np3avuXeO9+1NXhj33sU7LGEO\n-----END PRIVATE KEY-----\n";
const GOOGLE_SERVICE_ACCOUNT_EMAIL =
  "rsvp-invitation-wedding@grounded-web-417414.iam.gserviceaccount.com";
const AUTH_TOKEN_GOOGLE = "";

export {
  API_KEY,
  CLIENT_ID,
  AUTH_TOKEN_GOOGLE,
  GOOGLE_PRIVATE_KEY,
  GOOGLE_SHEETS_ID,
  GOOGLE_SERVICE_ACCOUNT_EMAIL,
  RANGE_SHEETS,
};
