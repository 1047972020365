import React from "react";
import { motion } from "framer-motion";

export default function FirstCoverOfLeftSection() {
  return (
    <div
      id="left-part-section"
      className="fixed inset-0 top-0 left-0 bg-gradient-to-t from-primary via-primary/50 via-40% to-seconds/0 bg-blend-multiply"
    >
      <div className="w-full h-full flex flex-col justify-end items-start relative">
        <article className="w-[calc(50%+30px)] h-[300px] bg-transparent">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.3, delay: 0.5 }}
            className="w-fit h-fit text-center ml-[5rem]"
          >
            <p className="text-2xl font-thin font-pinyonScript gold-text leading-snug">
              The Wedding Of
            </p>
            <p className="text-7xl font-cormorantUpright gold-text leading-snug drop-shadow-[0_10px_8px_rgba(254,251,233,0.5)]">
              Putri & Fahmi
            </p>
            <p className="text-2xl font-prata text-beige italic leading-loose drop-shadow-[0_10px_8px_rgba(254,251,233,0.5)]">
              Sabtu, 07 September 2024
            </p>
          </motion.div>
        </article>
      </div>
    </div>
  );
}
