import "./App.css";
import Home from "./pages/Home";

function App() {
  return (
    <>
      <main className="w-[100lvw] h-[100lvh] relative bg-white">
        <Home />
      </main>
      <footer className="relative w-full">{/* <NavbarFloating /> */}</footer>
    </>
  );
}

export default App;
