import React, { useRef } from "react";
import BgQuote from "../assets/image/bg-quote.png";
import Leaf from "../assets/image/leaf-2.png";
import { useAnimation, useInView, motion } from "framer-motion";

export default function Quote() {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, {
    once: true, // Animasi hanya terjadi sekali
    threshold: 0.1, // Persentase elemen yang harus terlihat sebelum animasi dimulai
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <section className="w-full min-h-[500px] bg-beige leading-6 relative">
      <motion.article
        ref={ref}
        initial="visible"
        animate={controls}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="w-full h-[480px] py-6 px-4 bg-contain bg-no-repeat bg-center flex flex-col items-center justify-center"
        style={{ backgroundImage: `url(${BgQuote})` }}
      >
        <p className="w-[calc(calc(50%+50px)+20px)] text-xs md:text-sm text-center text-beige font-prata font-normal italic">
          Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu
          isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa
          tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan
          sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat
          tanda-tanda bagi kaum yang berfikir.
          <br />
          <br />
          <b>(Q.S Ar Rum : 21)</b>
        </p>
      </motion.article>
      <img
        src={Leaf}
        alt=""
        className="object-contain absolute bottom-[0em] left-[-3em] transform scale-x-[-1] w-[210px] h-[300px]"
      />
    </section>
  );
}
