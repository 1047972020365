import React from "react";
import FirstCoverOfLeftSection from "../components/FirstCoverOfLeftSection";
import MobileView from "../components/MobileView";

export default function Home() {
  return (
    <>
      <section className="w-[500px] md:w-full h-full hidden md:flex md:flex-row md:items-start bg-white">
        {/* Part left */}
        <div className="shrink md:w-[calc(60%)] xl:w-[calc(70%)] h-full bg-[url('/src/assets/prewedding/primary_cover.JPG')] bg-cover bg-origin-border bg-center bg-no-repeat">
          <FirstCoverOfLeftSection />
        </div>
        {/* End */}
        {/* Part right */}
        <div className="flex flex-col justify-start w-full md:w-[calc(40%)] xl:w-[calc(30%)] h-full mx-auto bg-white overflow-hidden relative">
          <MobileView />
        </div>
        {/* End */}
      </section>
      {/* Mobile View */}
      <section className="w-full h-full overflow-x-hidden relative md:hidden">
        <MobileView />
      </section>
    </>
  );
}
