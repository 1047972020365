import { motion } from "framer-motion";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import React from "react";
import {
  Calender,
  Camera,
  Gift,
  Heart,
  History,
  OpenMail,
} from "../utils/Icons";

gsap.registerPlugin(ScrollToPlugin);

const menus = [
  { name: "date-view-elementor", icon: <OpenMail /> },
  { name: "couple-elementor", icon: <Heart /> },
  { name: "event-elementor", icon: <Calender /> },
  { name: "galery-elementor", icon: <Camera /> },
  { name: "story-love-elementor", icon: <History /> },
  { name: "wedding-gift-elementor", icon: <Gift /> },
];

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 0.8,
  },
};

export const NavbarFloating = () => {
  const scrollToSection = (targetId) => {
    const targetSection = document.getElementById(targetId);
    console.log(targetSection);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    // if (targetSection) {
    //   gsap.to(window, {
    //     duration: 1,
    //     scrollTo: { y: targetSection, offsetY: 100 }, // offsetY opsional, untuk menambahkan jarak dari atas
    //     ease: "power2.inOut",
    //   });
    // }
  };

  // const controls = useAnimation();
  // const scrollToSection = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     controls.start({
  //       y: element.offsetTop,
  //       transition: { duration: 0.5, ease: "easeInOut" },
  //     });
  //   }
  // };

  return (
    <section
      id="navbar-floating"
      className="fixed bottom-5 right-0 bg-transparent transform translate-x-0 z-20 w-full md:w-[30%] h-14"
    >
      <motion.article
        id="box-menu-navbar"
        variants={container}
        initial="hidden"
        animate="visible"
        className="w-full h-full flex flex-row justify-center"
      >
        <motion.div
          variants={item}
          className="w-[calc(calc(50%+50px)+50px)] md:w-fit h-full rounded-lg flex flex-row items-center justify-center space-x-3 px-3 glass-sage-light drop-shadow-md"
        >
          {menus.map((menu, index) => (
            <button
              id={`btn-${menu.name}`}
              key={index}
              className="w-10 h-10 rounded-md bg-seconds bg-blend-multiply flex flex-col items-center justify-center"
              onClick={() => scrollToSection(`${menu.name}`)}
            >
              {menu.icon}
            </button>
          ))}
        </motion.div>
      </motion.article>
    </section>
  );
};
